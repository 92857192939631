.body {
  background-color: white;
  overflow-x: scroll;
  overflow-y: scroll;
}

.containerTable {
  overflow-x: scroll;
  overflow-y: scroll; /* or auto */
}
.App-actionButton {
  background-color: #09072c;
  color: rgb(182, 23, 23);
  border-radius: 15px 0px 15px 0px;
}

.App-paragraph {
  text-align: center;
  color: rgb(9, 12, 172);
  font-style: italic;
  font-weight: 300;
  font-family: "Times New Roman";
  font-size: medium;
}

.App-iplan {
  min-height: 20px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  /* background-color: rgb(135,177,250); */
  text-align: center;
  color: whitesmoke;
  font-style: italic;
  font-weight: 300;
  font-family: Georgia;
  font-size: medium;
  padding: 75px;
  height: 500px;
}

.App-plan {
  min-height: 20px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  /* background-color: rgb(135,177,250); */
  text-align: center;
  color: whitesmoke;
  font-style: italic;
  font-weight: 300;
  font-family: Georgia;
  font-size: medium;
  padding: 75px;
  background-image: url("./../../assets/images/planimage.jpg");
  height: 200px;
}

.App-question {
  min-height: 100px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  max-height: 40vw;
  max-width: 200vw;
  height: 192.5px;
  width: 1070px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-image: url("./../../assets/images/scenari.jpg");
}
.App-container {
  min-height: 100px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  max-height: 40vw;
  max-width: 200vw;
  height: 192.5px;
  width: 1100px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-image: url("./../../assets/images/back.jpg");
  font-family: "Times New Roman";
}

.App-questionList {
  font-size: 23px;
  font-weight: 300;
}

.App-questiontextbox {
  height: 50px;
  width: 600px;
}
.App-textarea {
  height: 50px;
  width: 600px;
}

.App-questiondisplay {
  height: 30px;
  width: 500px;
}

.App-questiontext {
  height: 50px;
  width: 300px;
}

.App-label {
  float: left;
  width: 25px;
}

.App-input {
  color: white;
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

.App-iplan {
  height: 25px;
  width: 1000px;
  text-align: center;
  font-style: italic;
}

.App-questionlayout {
  float: left;
  width: 25px;
}

.App-questionlayout2 {
  width: 100%;
  font-size: 100%;
  position: relative;
  color: white;
  padding: 10px 10px 10px 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.App-master {
  min-height: 100px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  max-height: 40vw;
  max-width: 200vw;
  height: 500px;
  width: 1430px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-image: url("./../../assets/images/lightblue.jpg");
}
.App-background {
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  max-width: 200vw;
  border: 1px solid #dadce0;
  border-radius: 8px;
}

.App-fedbackground {
  min-height: 100px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  /* max-height: 150vw; */
  max-width: 200vw;
  /* height: 2800px; */
  border: 1px solid #dadce0;
  border-radius: 8px;
}

.App-backimage {
  min-height: 100px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  /* max-height: 150vw; */
  max-width: 200vw;
  /* height: 1500px; */
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-image: url("./../../assets/images/gettyimages.jpg");
}
.App-thankyou {
  min-height: 550px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  /* max-height: 150vw; */
  /* height: 1500px; */
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-image: url("./../../assets/images/gettyimages.jpg");
}
.App-profilebackground {
  min-height: 100px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  max-height: 150vw;
  max-width: 200vw;
  height: 900px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-image: url("./../../assets/images/lawn.png");
}

.App-overlap-group1 {
  align-items: flex-end;
  background-color: transparent;
  background-size: 100% 100%;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 550px;
  justify-content: flex-start;
  margin-top: 0px;
  min-width: 1440px;
  padding: 50px 20px;
  width: 1440px;
  background-image: url("../../assets/images/rectangle42.png");
}

.App-overlap-group6-1 {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 0;
  height: 290px;
  position: relative;
  width: 568px;
}
.App-overlap-group5-1 {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 0;
  height: 290px;
  position: relative;
  width: 568px;
}

.App-overlap-group8 {
  align-items: flex-start;
  background-color: white;
  background-size: 50;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  margin-left: 25px;
  min-height: 300px;
  padding: 60px 50px;
  width: 665px;
  /*  background-image: url('../../assets/rectangle47.png'); */
}

.App-span-1 {
  color: rgba(26, 24, 24, 1);
  font-family: "Hind-Regular", Helvetica, Arial, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.App-are-you-a-federation {
  background-color: transparent;
  height: 130px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 55px;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 568px;
}

.App-montserrat-black-eerie-black-50px {
  color: var(--eerie-black);
  font-family: "Montserrat-Black", Helvetica, Arial, serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
}

.App-are-you-a--ganisation {
  background-color: transparent;
  height: 183px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 55px;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 568px;
}

.App-hind-normal-eerie-black-24px {
  color: var(--eerie-black);
  font-family: "Hind", Helvetica, Arial, serif;
  font-size: 24px;
}

.App-register-h--standards {
  background-color: transparent;
  height: 112px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 35px;
  position: absolute;
  text-align: left;
  top: 125px;
  width: 568px;
}
.App-register-h-governance {
  background-color: transparent;
  height: 112px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 35px;
  position: absolute;
  text-align: left;
  top: 125px;
  width: 568px;
}

.App-footer {
  align-items: left;
  background-color: transparent;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 500px;
  justify-content: flex-start;
  margin-top: 0px;
  min-width: 1440px;
  padding: 100px 100px;
  width: 1440px;
}

.App-auto-flex1 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  min-height: 339px;
  width: 500px;
}
.App-auto-flex2 {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 500px;
  justify-content: flex-start;
  margin-bottom: 1.67px;
  margin-left: 91px;
  min-height: 500px;
  width: 370px;
}

.App-contact-1 {
  background-color: transparent;
  flex-shrink: 0;
  height: auto;
  letter-spacing: 0px;
  line-height: 70px;
  margin-left: 1px;
  min-height: 81px;
  min-width: 339px;
  text-align: left;
  white-space: nowrap;
  width: 500;
}

.App-montserrat-black-eerie-black-65px {
  color: var(--eerie-black);
  font-family: "Montserrat-Black", Helvetica, Arial, serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
}

.App-christian--cimindiain {
  background-color: transparent;
  flex-shrink: 0;
  height: 254px;
  letter-spacing: 0px;
  line-height: 35px;
  margin-top: 3px;
  min-height: 254px;
  text-align: left;
  width: 652px;
}
.App-span {
  color: rgba(26, 24, 24, 1);
  font-family: "Hind-Bold", Helvetica, Arial, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.App-contact-us {
  align-items: flex-start;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  min-height: 1080px;
  width: 1920px;
}

/* .App-about-overlap-group5 {
  background-color:transparent;
  background-size: 100% 100%;
  display: inline-flex;
  flex-direction: column;
  height: 650px;
  justify-content: flex-start;
  left: 0px;
  min-height: 400px;
  padding: 50px 50px;
  position: absolute;
  top: 800px;
  width: 1440px;
  background-image: url('../../assets/images/rectangle1.png');
} */
.App-about-overlap-group5 {
  align-items: center;
  background-color: transparent;
  background-size: 100% 100%;
  flex-direction: column;
  flex-shrink: 0;
  height: 600px;
  justify-content: flex-start;
  margin-top: 0px;
  min-width: 1440px;
  padding: 0 px 0px;
  width: 1440;
  background-image: url("../../assets/images/rectangle1.png");
}

.App-about-text-13 {
  align-self: center;
  background-color: transparent;
  flex-shrink: 0;
  height: auto;
  letter-spacing: -1.62px;
  line-height: 70px;
  margin-top: 50px;
  margin-left: 0px;
  min-height: 81px;
  min-width: 500px;
  text-align: center;
  white-space: nowrap;
  width: 1000px;
}

.App-about-montserrat-black-white-65px {
  color: white;
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
}

.App-about-auto-flex1 {
  align-items: center;
  background-color: transparent;
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-end;
  margin-bottom: 1px;
  margin-left: 200px;
  min-height: 500px;
  width: 370px;
}
.App-about-auto-flex2 {
  align-items: center;
  background-color: transparent;
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  margin-left: 250px;
  min-height: 504px;
  width: 370px;
}
.App-about-auto-flex3 {
  align-items: center;
  background-color: transparent;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 500px;
  justify-content: flex-end;
  margin-top: 38px;
  margin-left: 10px;
  min-width: 1440px;
  width: 1400;
}

.App-about-text-20 {
  text-align: left;
  align-items: left;
  background-color: transparent;
  color: var(--eerie-black);
  flex-shrink: 0;
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: px;
  line-height: 30px;
  min-height: 101px;
  min-width: 322px;
  white-space: nowrap;
  width: 1440;
  margin-top: 20px;
  margin-left: 0px;
}

.App-about-text-21 {
  text-align: left;
  align-items: left;
  background-color: transparent;
  color: white;
  flex-shrink: 0;
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: px;
  line-height: 30px;
  min-height: 50px;
  min-width: 322px;
  text-align: left;
  white-space: nowrap;
  width: 1440;
}
.App-about-text-18 {
  background-color: transparent;
  flex-shrink: 0;
  height: 395px;
  letter-spacing: 0px;
  line-height: 50px;
  margin-top: 7px;
  min-height: 326px;
  text-align: left;
  width: 508px;
}
.App-about-text-19 {
  background-color: transparent;
  flex-shrink: 0;
  height: 395px;
  letter-spacing: 0px;
  line-height: 50px;
  margin-top: 7px;
  min-height: 395px;
  text-align: left;
  width: 559px;
}

.App-about-hind-regular-normal-white-40px {
  color: white;
  font-family: "Hind-Regular-Black", Helvetica, Arial, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.App-about-hind-regular-normal-white-41px {
  color: white;
  font-family: "Hind-Regular-Black", Helvetica, Arial, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
}

.App-about-overlap-group4 {
  align-items: center;
  background-color: white;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 980px;
  justify-content: flex-start;
  margin-top: 0px;
  min-width: 1920px;
  padding: 122.5px 278px;
  width: 1440;
}

.App-about-auto-flex4 {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  margin-top: 5.5px;
  min-height: 294px;
  width: 531px;
}

.App-about-address {
  background-color: transparent;
  flex-shrink: 0;
  height: auto;
  letter-spacing: -1.62px;
  line-height: 70px;
  min-height: 221px;
  min-width: 531px;
  text-align: left;
  white-space: nowrap;
  width: auto;
}

.App-about-group-19 {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 0;
  height: 735px;
  margin-left: 109px;
  position: relative;
  width: 724px;
}

.App-about-auto-flex {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  margin-left: 67px;
  min-height: 745px;
  width: 676px;
}

.App-contact-overlap-group3 {
  align-items: flex-start;
  background-color: transparent;
  background-size: 100% 100%;
  border-radius: 30px;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  min-height: 500px;
  padding: 50px 50px;
  width: 700px;
  background-image: url("../../assets/images/contact-blue.png");
}

.App-contact-auto-flex1 {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  min-height: 288px;
  width: 398px;
}

App-contact-auto-flex3 {
  align-items: flex-end;
  background-color: transparent;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 288px;
  justify-content: flex-start;
  min-width: 816px;
  width: auto;
}

.App-contact-contact-us {
  background-color: transparent;
  flex-shrink: 0;
  height: auto;
  letter-spacing: 0px;
  line-height: 50px;
  min-height: 51px;
  min-width: 240px;
  text-align: left;
  white-space: nowrap;
  width: auto;
}

.App-contact-montserrat-black-white-40px {
  color: white;
  font-family: "Montserrat-Black", Helvetica, Arial, serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
}

.App-contact-regular-normal-white-30px {
  color: white;
  font-family: "Montserrat-Black", Helvetica, Arial, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

.App-contact-follow-us {
  background-color: transparent;
  flex-shrink: 0;
  height: auto;
  letter-spacing: 0px;
  line-height: 36px;
  min-height: 39px;
  min-width: 166px;
  text-align: left;
  white-space: nowrap;
  width: auto;
}

.App-contact-montserrat-black-eerie-black-30px {
  color: var(--eerie-black);
  font-family: "Montserrat-Black", Helvetica, Arial, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
}

.App-contact-auto-flex {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 123.51px;
  margin-left: 96px;
  min-height: 106px;
  width: 370px;
}

.App-contact-overlap-group1 {
  align-items: flex-start;
  background-color: white;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 700px;
  justify-content: flex-start;
  margin-top: 0px;
  min-width: 800px;
  padding: 30px 30px;
  width: auto;
}
.App-a {
  list-style-type: upper-roman;
}

.App-support {
  min-height: 20px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  /* background-color: rgb(135,177,250);  */
  text-align: center;
  padding: 50px;
  background-image: url("../../assets/images/are_you_support.jpeg");
  height: 200px;
}
.table-scroll {
  max-height: 600px;
  overflow: auto;
  margin-top: 20px;
}
.mandatory-field {
  color: red;
  font-size: 17px;
}
.label-field {
  font-weight: bold;
  font-size: 13px;
}
.dash-card-header {
  margin-left: "85px";
  margin-right: "85px";
  font-size: 16;
  font-style: "Italic";
}
.panel-header {
  background-color: #485dd4;
  border-radius: 14px;
}
.panel-label {
  padding-top: 12px !important;
  color: whitesmoke !important;
}

.App-hyperlink {
  text-decoration-line: underline;
  color: #3366bb !important ;
}

.responsive-tab {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}

.popup-header {
  height: 7vh !important;
  padding-top: 16px !important;
  padding-left: 20px !important;
  color: white !important;
}
.mainmenunav ul.mainmenu > li:hover {
  background: #2185d0;
  border-radius: 10px;
}
.home-menu {
  font-weight: bold;
  padding: 12px !important;
}
.home-menu:hover {
  color: white !important;
}
.ui.steps .step.active .title {
  color: white !important;
}
.ui.steps .step.active {
  background: #2185d0 !important;
}
.ui.steps .step.active:hover {
  background: #2185c0 !important;
}
.ui.steps .step.active .description {
  color: white !important;
}
